import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    OnDestroy,
    ElementRef,
    Output
} from '@angular/core';

import { ModalService } from '../../shared/modal/modal.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    @Input() titulo: string;
    @Input() centralizado: boolean;
    @Input() tamanho: string;
    @Input() confirmarFechamento: boolean;

    @Output() close = new EventEmitter();
    @Output() fecharModal = new EventEmitter();
    private element: any;
    classes: any[] = [];

    constructor(private modalService: ModalService, private el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        const modal = this;

        if (!this.id) {
            console.error('O modal precisa ter um id');
            return;
        }

        if (this.tamanho) {
            if (this.tamanho.includes('modal-width')) {
                this.classes.push(this.tamanho);
            } else {
                this.classes.push(`modal-${this.tamanho}`);
            }
        }

        if (this.centralizado) {
            this.classes.push('modal-dialog-centered');
        }

        document.body.appendChild(this.element);

        // this.element.addEventListener('click', function(e: any) {
        //     if (e.target.classList.contains('modal')) {
        //         modal.fecha();
        //     }
        // });

        this.modalService.add(this);
    }

    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    abre(): void {
        this.element.firstChild.style.display = 'block';

        setTimeout(() => {
            this.element.firstChild.classList.add('show');
            document.body.classList.add('modal-open');
        }, 1);
    }

    fecha(): void {
        this.close.emit();
        this.element.firstChild.classList.remove('show');
        document.body.classList.remove('modal-open');

        setTimeout(() => (this.element.firstChild.style.display = 'none'), 200);
    }

    confirmaFechamento() {
        this.fecharModal.emit();
    }
}
