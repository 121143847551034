import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

import { Menu, ActiveMenu } from './menu';
import { MENUS } from './menu.mock';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    private apiUrl = environment.API_URL;
    
    openMenu: Observable<any>;
    private menuStatus = new Subject<any>();

    private menuObject = new BehaviorSubject<any>(new ActiveMenu());
    activeMenu = this.menuObject.asObservable();

    private breadcrumbMenu = new BehaviorSubject<any>(new Array());
    breadcrumbMenuStatus = this.breadcrumbMenu.asObservable();

    private breadcrumb = [];

    constructor(private http: HttpClient) {
        this.openMenu = this.menuStatus.asObservable();
    }

    all(): Observable<Menu[]> {
        return Observable.create(function(observer: any) {
            observer.next(MENUS);
        });
    }

    setMenu(menu: any): void {
        console.log('menu');
        if (menu.level === 1) {
            this.breadcrumb = [];
        }
        if (!this.breadcrumb.includes(menu) && menu.children.length > 0) {
            this.breadcrumb.push(menu);
        }
        this.menuObject.next(menu);
        this.breadcrumbMenu.next(this.breadcrumb);
    }

    backMenu(): void {
        const newMenu = this.breadcrumb[
            this.breadcrumb.indexOf(this.activeMenu.source['_value']) - 1
        ];
        this.breadcrumb.splice(
            this.breadcrumb.indexOf(this.activeMenu.source['_value']),
            1
        );
        this.setMenu(newMenu);
    }

    setStatus(status: boolean): void {
        this.menuStatus.next(status);
    }

    getMenusAutorizados(menusPerfil: any[]) {
        let menus;
        if(menusPerfil !== undefined && menusPerfil?.length !== 0) {
            menus = MENUS.filter(menu => {
                return menusPerfil.some(menuPerfil => (menuPerfil.dscMenu.toUpperCase() == menu.name.toUpperCase()) || menu.name == "Sair");
            });
        } else {
            menus = MENUS;
        }

        return menus
    }

    getMenusPerfil(codigo) {
        return this.http.get(`${this.apiUrl}/dominios/perfilAcesso/listaMenus/${codigo}`);
    }

}
